<template>
  <div>
    <v-app>
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="d-flex">
              <!-- <v-avatar class="ma-12" size="400" tile>
                        <v-img :src="src"></v-img>
              </v-avatar>-->
              <div>
                <v-card-text class="subtitle-1">
                  <div>
                    <h2>Select a plan that best suits you.</h2>
                    <br />
                    <div class="pricing-wrapper">
                      <div class="row no-gutters">
                        <div class="col-md-3" v-if="subscriptionsize==4">
                          <div class="pricing panel box bg-white shadow">
                            <div class="panel-heading">
                              <h4
                                class="panel-title mb-35"
                                v-if="subscriptionsize==4"
                              >{{subscriptions[0].title}}</h4>
                              <div class="prices">
                                <div class="price price-show">
                                  <span class="price-currency"></span>
                                  <span class="price-value">free</span>
                                  <span class="price-duration">lifetime</span>
                                </div>
                                <div class="price price-hide">
                                  <span class="price-currency">$</span>
                                  <span class="price-value">99</span>
                                  <span class="price-duration">year</span>
                                </div>
                              </div>
                            </div>
                            <!--/.panel-heading -->
                            <div class="panel-body">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <td>
                                      <strong>500Mb</strong> Storage
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Unlimited</strong> Business Listing
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Unlimited</strong> Bandwidth
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Content Delivery Network (CDN)</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Free Domain</strong> under Yelko
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Yelko
                                      <strong>Marketplace</strong>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      Use free
                                      <strong>Templates</strong> or
                                      <strong>Plugins</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Chat and Email
                                      <strong>Support</strong> - 24 hr
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <!--/.panel-body -->
                            <div class="panel-footer" v-if="!website.active_cart">
                              <v-btn
                                outlined
                                class="btn info text-white"
                                v-if="subscriptionsize==4"
                                @click="buildCartObject(subscriptions[0])"
                              >Continue With Free Plan</v-btn>
                            </div>
                          </div>
                          <!--/.pricing -->
                        </div>
                        <!--/column -->
                        <div class="col-md-3" v-if="subscriptionsize>=3">
                          <div class="pricing panel box bg-white shadow">
                            <div class="panel-heading">
                              <h4
                                class="panel-title mb-35"
                                v-if="subscriptionsize==3"
                              >{{subscriptions[0].title}}</h4>
                              <h4 class="panel-title mb-35" v-else>{{subscriptions[1].title}}</h4>
                              <div class="prices">
                                <div class="price price-show">
                                  <span class="price-currency">Rs.</span>
                                  <span class="price-value">3499</span>
                                  <span class="price-duration">year</span>
                                </div>
                                <div class="price price-hide">
                                  <span class="price-currency">$</span>
                                  <span class="price-value">199</span>
                                  <span class="price-duration">year</span>
                                </div>
                              </div>
                            </div>
                            <!--/.panel-heading -->
                            <div class="panel-body">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <td>
                                      <strong>5Gb</strong> Storage
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Unlimited</strong> Business Listing
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Unlimited</strong> Bandwidth
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Content Delivery Network (CDN)</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Connect your Domain</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      No
                                      <strong>Ads</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Yelko
                                      <strong>Marketplace</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Use free
                                      <strong>Templates</strong> or
                                      <strong>Plugins</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Chat, Email & Phone
                                      <strong>Support</strong> -6hr
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <!--/.panel-body -->
                            <div class="panel-footer" v-if="!website.active_cart">
                              <v-btn
                                outlined
                                class="btn teal text-white"
                                v-if="subscriptionsize==3"
                                @click="buildCartObject(subscriptions[0])"
                              >Purchase Plan</v-btn>
                              <v-btn
                                outlined
                                class="btn teal text-white"
                                v-else
                                @click="buildCartObject(subscriptions[1])"
                              >Purchase Plan</v-btn>
                            </div>
                          </div>
                          <!--/.pricing -->
                        </div>
                        <!--/column -->
                        <div class="col-md-3 popular" v-if="subscriptionsize>=2">
                          <div class="pricing panel box bg-white shadow">
                            <div class="panel-heading">
                              <h4
                                class="panel-title mb-35"
                                v-if="subscriptionsize==2"
                              >{{subscriptions[0].title}}</h4>
                              <h4
                                class="panel-title mb-35"
                                v-else-if="subscriptionsize==3"
                              >{{subscriptions[1].title}}</h4>
                              <h4 class="panel-title mb-35" v-else>{{subscriptions[2].title}}</h4>
                              <div class="prices">
                                <div class="price price-show">
                                  <span class="price-currency">Rs.</span>
                                  <span class="price-value">5499</span>
                                  <span class="price-duration">year</span>
                                </div>
                                <div class="price price-hide">
                                  <span class="price-currency">$</span>
                                  <span class="price-value">499</span>
                                  <span class="price-duration">year</span>
                                </div>
                              </div>
                            </div>
                            <!--/.panel-heading -->
                            <div class="panel-body">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <td>
                                      <strong>10Gb</strong> Storage
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Unlimited</strong> Business Listing
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Unlimited</strong> Bandwidth
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Content Delivery Network (CDN)</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Connect your Domain</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      No
                                      <strong>Ads</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Free SSL Certificate</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Yelko
                                      <strong>Marketplace</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Use free & premium
                                      <strong>Templates</strong> or
                                      <strong>Plugins</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Chat, Email & Phone
                                      <strong>Support</strong> -6hr
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <!--/.panel-body -->
                            <div class="panel-footer" v-if="!website.active_cart">
                              <v-btn
                                outlined
                                class="btn teal text-white"
                                v-if="subscriptionsize==2"
                                @click="buildCartObject(subscriptions[0])"
                              >Purchase Plan</v-btn>
                              <v-btn
                                outlined
                                class="btn teal text-white"
                                v-if="subscriptionsize==3"
                                @click="buildCartObject(subscriptions[1])"
                              >Purchase Plan</v-btn>
                              <v-btn
                                outlined
                                class="btn teal text-white"
                                v-else
                                @click="buildCartObject(subscriptions[2])"
                              >Purchase Plan</v-btn>
                            </div>
                          </div>
                          <!--/.pricing -->
                        </div>
                        <!--/column -->
                        <div class="col-md-3" v-if="subscriptionsize>=1">
                          <div class="pricing panel box bg-white shadow">
                            <div class="panel-heading">
                              <h4
                                class="panel-title mb-35"
                                v-if="subscriptionsize==4"
                              >{{subscriptions[3].title}}</h4>
                              <h4
                                class="panel-title mb-35"
                                v-else-if="subscriptionsize==3"
                              >{{subscriptions[2].title}}</h4>
                              <h4
                                class="panel-title mb-35"
                                v-else-if="subscriptionsize==2"
                              >{{subscriptions[1].title}}</h4>
                              <h4 class="panel-title mb-35" v-else>{{subscriptions[0].title}}</h4>
                              <div class="prices">
                                <div class="price price-show">
                                  <span class="price-currency">Rs.</span>
                                  <span class="price-value">11999</span>
                                  <span class="price-duration">year</span>
                                </div>
                                <div class="price price-hide">
                                  <span class="price-currency">$</span>
                                  <span class="price-value">499</span>
                                  <span class="price-duration">year</span>
                                </div>
                              </div>
                            </div>
                            <!--/.panel-heading -->
                            <div class="panel-body">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <td>
                                      <strong>10Gb</strong> Storage
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Unlimited</strong> Business Listing
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Unlimited</strong> Bandwidth
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Content Delivery Network (CDN)</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Connect your Domain</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      No
                                      <strong>Ads</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Free SSL Certificate</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Yelko
                                      <strong>Marketplace</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Use free & premium
                                      <strong>Templates</strong> or
                                      <strong>Plugins</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Ecommerce
                                      <strong>Platform</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Payment
                                      <strong>Gateways</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Reports &
                                      <strong>Analytics</strong>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Chat, Email & Phone
                                      <strong>Support</strong> -6hr
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <!--/.panel-body -->
                            <div class="panel-footer" v-if="!website.active_cart">
                              <v-btn
                                outlined
                                class="btn teal text-white"
                                v-if="subscriptionsize==4"
                                @click="buildCartObject(subscriptions[3])"
                              >Purchase Plan</v-btn>
                              <v-btn
                                outlined
                                class="btn teal text-white"
                                v-else-if="subscriptionsize==3"
                                @click="buildCartObject(subscriptions[2])"
                              >Purchase Plan</v-btn>
                              <v-btn
                                outlined
                                class="btn teal text-white"
                                v-else-if="subscriptionsize==2"
                                @click="buildCartObject(subscriptions[1])"
                              >Purchase Plan</v-btn>
                              <v-btn
                                outlined
                                class="btn teal text-white"
                                v-else
                                @click="buildCartObject(subscriptions[0])"
                              >Purchase Plan</v-btn>
                            </div>
                          </div>
                          <!--/.pricing -->
                        </div>

                            <p class="text-danger" v-if="website.active_cart">
                              <small>
                                *you have active cart continue to
                                <router-link
                                  :to="{name: 'cart'}"
                                > Cart.</router-link>
                                  or continue to
                                <router-link
                                  :to="{name: 'quicksetup', params: { domainname: siteUrl }}"
                                >Quick setup.</router-link>

                              </small>
                            </p>
          <p><br> Note: All prices are exlusive of government service tax.</p>
                        <!--/column -->
                      </div>
                      <!--/.row -->
                    </div>
                  </div>
                </v-card-text>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import Website from "@/services/Websites/WebsiteService";
import SubscriptionService from "@/services/subscription/SubscriptionService";

const subscriptionService = new SubscriptionService();
const WebsiteService = new Website();

import { mapActions } from "vuex";

export default {
  name: "website-created",
  data() {
    return {
      src: "/assets/media/general/setup_website.png",
      subscriptions: [],
      subscriptionsize: null,
      cart: {},
      website: {
        id: null,
        title: null,
        user_id: null,
        template_id: null,
        is_custom_domain: null,
        plan_id: null,
        plan_expiry_date: null,
        lifetime_membership: null,
        is_linked_organisation: null,
        is_active: null,
        linked_organisation_id: null
      }
    };
  },
  mounted() {
    this.initWebsite();
    this.getWebsiteSubscription();
  },
  methods: {
    ...mapActions(["ADD_TO_CART"]),
    initWebsite() {
      WebsiteService.show(this.siteUrl).then(res => {
        this.website = res.data.website;
      });
    },
    getWebsiteSubscription() {
      subscriptionService
        .getWebsiteSubscription(this.siteUrl)
        .then(response => {
          this.subscriptions = response.data.plan;
          this.subscriptionsize = response.data.size;
        })
        .catch(error => {

        });
    },
    buildCartObject(item) {
      this.cart = {
        item_type: "website",
        purchase_type: "new",
        terms: "12",
        website_id: this.website.id,
        plan_id: item.id,
        item_price: item.price,
        is_processed: 0
      };
      this.addToCart();
    },
    addToCart() {
      if (this.cart) {
        this.ADD_TO_CART(this.cart)
          .then(response => {
            setTimeout(() => {
              this.$snotify.success("Added to cart");
              this.$router.push({
                name: "quicksetup",
                params: { domainname: this.siteUrl }
              });
            }, 2000);
          })
          .catch(error => {
            this.$snotify.error("Error occured");
          });
      }
    }
  },
  computed: {
    siteUrl() {
      return this.$route.params.domainname;
    }
  }
};
</script>
